var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var __classPrivateFieldSet = (this && this.__classPrivateFieldSet) || function (receiver, state, value, kind, f) {
    if (kind === "m") throw new TypeError("Private method is not writable");
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a setter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot write private member to an object whose class did not declare it");
    return (kind === "a" ? f.call(receiver, value) : f ? f.value = value : state.set(receiver, value)), value;
};
var _PageSearchController_instances, _PageSearchController_restoreSearchFromUri, _AnimeCardListController_instances, _AnimeCardListController_debouncedCheckEmpty, _AnimeCardListController_currentSearchQuery, _AnimeCardListController_checkEmpty, _AnimeCardController_instances, _AnimeCardController_animeId, _AnimeCardController_userPreferredTitleValue, _AnimeCardController_romajiTitle, _AnimeCardController_englishTitle, _AnimeCardController_nativeTitle, _AnimeCardController_alternateTitlesValue, _AnimeCardController_cachedSearchQueryString, _AnimeCardController_hasBeenInitialized, _AnimeCardController_isRemoved, _AnimeCardController_isVisible, _AnimeCardController_synopsisRevealed, _AnimeCardController_countdownEntry, _AnimeCardController_libraryInitialized, _AnimeCardController_subscriptions, _AnimeCardController_closeCallback, _AnimeCardController_ioUtil, _AnimeCardController_lazyLoader, _AnimeCardController_initializeLibrary, _AnimeCardController_teardownCountdown, _AnimeCardController_createEventData_get, _AnimeCardController_applyLibraryStatus, _AnimeCardController_remove, _AnimeCardController_hide, _AnimeCardController_unhide, _AnimeCardController_matchesSearchQuery, _AnimeCardController_searchQueryString_get;
import { application, Controller } from '@local-stimulus';
import vebounce from '@lib/vebounce';
import AnimeStreamsModal from '@root/modal/anime-streams-modal';
import AnimeVideosModal from '@root/modal/anime-videos-modal';
import AnimeLinksModal from '@root/modal/anime-links-modal';
import LibraryEditor from '@root/modal/library-editor-modal';
import CollectionCountdownRunner from '@root/javascript/countdown/collection-countdown-runner';
import { showTimeZoneConversionModal } from '@root/lazy-modules/time-zone-conversion-modal';
import MarkFilterModal from '@root/modal/mark-filter-modal';
import { LibraryStatus } from '@root/javascript/library/constants';
import HandlebarsCompilerFactory from '@root/javascript/handlebars/compiler-factory';
import '@root/javascript/common/options-bar';
import { trackRelatedLinkClick } from '@lib/analytics';
import DomJson from '@utils/dom-json';
import { setLibraryStatusAttribute } from '@utils/user-library-utils';
import { Subscription } from 'rxjs';
import { conditionallyShowOnboardingModal } from '@root/release-schedule-onboarding/v1/launcher';
if (!window.matchMedia('(max-width: 700px)').matches) {
    conditionallyShowOnboardingModal();
}
const { Viewer } = window;
const COUNTDOWN_RUNNER = new CollectionCountdownRunner();
const MARK_FILTERS_SCOPE = DomJson('mark_filters_scope');
const MARK_FILTERING_ENABLED = document.querySelector('script#mark_filters') !== null;
const HIDDEN_BY_MARK_FILTER_CSS_CLASS = 'hidden-by-mark-filter';
const MarkFilter = Viewer.markFilter(MARK_FILTERS_SCOPE);
const handlebarsCompilerFactor = new HandlebarsCompilerFactory();
class PageSearchController extends Controller {
    constructor() {
        super(...arguments);
        _PageSearchController_instances.add(this);
        this.performSearchDebounced = vebounce(this.performSearch.bind(this), 50);
    }
    connect() {
        window.addEventListener('DOMContentLoaded', () => {
            __classPrivateFieldGet(this, _PageSearchController_instances, "m", _PageSearchController_restoreSearchFromUri).call(this);
        }, { once: true });
    }
    performSearch(e) {
        e === null || e === void 0 ? void 0 : e.preventDefault();
        const term = this.inputTarget.value;
        const detail = {};
        if (term) {
            detail.term = term;
            detail.termLower = term.toLowerCase();
        }
        this.dispatch('search', { detail });
        const uriEncodedTerm = encodeURIComponent(term);
        window.history.replaceState({}, document.title, (function () {
            // If no search term is set, remove the anchor entirely.
            return `${window.location.pathname}${window.location.search}${term ? `#${uriEncodedTerm}` : ''}`;
        }()));
    }
    cancelSearch() {
        this.inputTarget.value = '';
        this.performSearch();
    }
    hideKeyboard(e) {
        e === null || e === void 0 ? void 0 : e.preventDefault();
        this.inputTarget.blur();
    }
}
_PageSearchController_instances = new WeakSet(), _PageSearchController_restoreSearchFromUri = function _PageSearchController_restoreSearchFromUri() {
    const urlHash = window.location.hash;
    if (urlHash) {
        this.inputTarget.value = decodeURIComponent(urlHash.replace('#', ''));
        this.performSearch();
    }
};
PageSearchController.targets = ['input'];
class AnimeCardListController extends Controller {
    constructor() {
        super(...arguments);
        _AnimeCardListController_instances.add(this);
        _AnimeCardListController_debouncedCheckEmpty.set(this, vebounce(__classPrivateFieldGet(this, _AnimeCardListController_instances, "m", _AnimeCardListController_checkEmpty).bind(this), 50));
        _AnimeCardListController_currentSearchQuery.set(this, void 0);
    }
    checkEmptiness() {
        __classPrivateFieldGet(this, _AnimeCardListController_debouncedCheckEmpty, "f").call(this);
    }
    cardTargetDisconnected() {
        this.checkEmptiness();
    }
    performSearch({ detail }) {
        const { classList } = this.searchMessageTarget;
        __classPrivateFieldSet(this, _AnimeCardListController_currentSearchQuery, detail.term, "f");
        if (this.hasCardTarget && detail.term) {
            this.searchTermOutputTarget.textContent = detail.term;
            classList.remove('hide');
        }
        else {
            classList.add('hide');
        }
        this.dispatch('search', { detail });
    }
    cancelSearch(e) {
        e === null || e === void 0 ? void 0 : e.preventDefault();
        this.dispatch('search-cancelled', { prefix: null });
    }
    doGlobalSearch(e) {
        e === null || e === void 0 ? void 0 : e.preventDefault();
        const currentSearchQuery = __classPrivateFieldGet(this, _AnimeCardListController_currentSearchQuery, "f");
        if (currentSearchQuery) {
            window.location.href = `/search?q=${encodeURIComponent(currentSearchQuery)}`;
        }
    }
    showMarkFilter(e) {
        e === null || e === void 0 ? void 0 : e.preventDefault();
        MarkFilterModal.show(e.target.dataset.scope);
    }
}
_AnimeCardListController_debouncedCheckEmpty = new WeakMap(), _AnimeCardListController_currentSearchQuery = new WeakMap(), _AnimeCardListController_instances = new WeakSet(), _AnimeCardListController_checkEmpty = function _AnimeCardListController_checkEmpty() {
    const { classList } = this.emptyMessageTarget;
    const isEmpty = !this.hasCardTarget;
    if (isEmpty) {
        classList.remove('hide');
    }
    else {
        classList.add('hide');
    }
    if (this.hasMarkFilterButtonTarget) {
        if (isEmpty && !MarkFilter.isEmpty()) {
            this.markFilterButtonTarget.classList.remove('hide');
        }
        else {
            this.markFilterButtonTarget.classList.add('hide');
        }
    }
};
AnimeCardListController.targets = ['card', 'emptyMessage', 'searchMessage', 'searchTermOutput', 'markFilterButton'];
class AnimeCardController extends Controller {
    constructor() {
        super(...arguments);
        _AnimeCardController_instances.add(this);
        _AnimeCardController_animeId.set(this, void 0);
        _AnimeCardController_userPreferredTitleValue.set(this, void 0);
        _AnimeCardController_romajiTitle.set(this, void 0);
        _AnimeCardController_englishTitle.set(this, void 0);
        _AnimeCardController_nativeTitle.set(this, void 0);
        _AnimeCardController_alternateTitlesValue.set(this, void 0);
        _AnimeCardController_cachedSearchQueryString.set(this, void 0);
        _AnimeCardController_hasBeenInitialized.set(this, false);
        _AnimeCardController_isRemoved.set(this, false);
        _AnimeCardController_isVisible.set(this, null);
        _AnimeCardController_synopsisRevealed.set(this, false);
        _AnimeCardController_countdownEntry.set(this, void 0);
        _AnimeCardController_libraryInitialized.set(this, false);
        _AnimeCardController_subscriptions.set(this, new Subscription());
        _AnimeCardController_closeCallback.set(this, void 0);
        _AnimeCardController_ioUtil.set(this, this.application.ioUtil);
        _AnimeCardController_lazyLoader.set(this, this.application.ioLazyLoader);
    }
    connect() {
        __classPrivateFieldSet(this, _AnimeCardController_isRemoved, false, "f");
        const { dataset } = this.element;
        const { animeId } = dataset;
        const { library } = Viewer;
        const initialLibraryStatus = library.statusOf(animeId);
        __classPrivateFieldSet(this, _AnimeCardController_animeId, animeId, "f");
        __classPrivateFieldGet(this, _AnimeCardController_instances, "m", _AnimeCardController_applyLibraryStatus).call(this, initialLibraryStatus);
        if (__classPrivateFieldGet(this, _AnimeCardController_isRemoved, "f")) {
            // Abort early if the card was removed
            return;
        }
        __classPrivateFieldSet(this, _AnimeCardController_romajiTitle, dataset.romaji, "f");
        __classPrivateFieldSet(this, _AnimeCardController_englishTitle, dataset.english, "f");
        __classPrivateFieldSet(this, _AnimeCardController_nativeTitle, dataset.native, "f");
        if (this.hasCountdownTarget) {
            const { countdownTarget } = this;
            __classPrivateFieldSet(this, _AnimeCardController_countdownEntry, COUNTDOWN_RUNNER.addElement(countdownTarget, countdownTarget.dataset.label), "f");
        }
        __classPrivateFieldGet(this, _AnimeCardController_lazyLoader, "f").observe(this.posterTarget);
        __classPrivateFieldSet(this, _AnimeCardController_hasBeenInitialized, true, "f");
        __classPrivateFieldGet(this, _AnimeCardController_subscriptions, "f").add(library.changes$.subscribe((change) => {
            var _a;
            if (change.animeId === animeId) {
                __classPrivateFieldGet(this, _AnimeCardController_instances, "m", _AnimeCardController_applyLibraryStatus).call(this, (_a = change.attributes) === null || _a === void 0 ? void 0 : _a.status);
            }
        }));
        __classPrivateFieldGet(this, _AnimeCardController_ioUtil, "f").observe(this.element, (entry) => {
            if (entry.isIntersecting) {
                __classPrivateFieldGet(this, _AnimeCardController_instances, "m", _AnimeCardController_initializeLibrary).call(this, animeId, initialLibraryStatus);
                return true;
            }
            return false;
        });
    }
    applySearch({ detail, }) {
        const { termLower } = detail;
        if (!termLower || __classPrivateFieldGet(this, _AnimeCardController_instances, "m", _AnimeCardController_matchesSearchQuery).call(this, termLower)) {
            __classPrivateFieldGet(this, _AnimeCardController_instances, "m", _AnimeCardController_unhide).call(this);
        }
        else {
            __classPrivateFieldGet(this, _AnimeCardController_instances, "m", _AnimeCardController_hide).call(this);
        }
    }
    disconnect() {
        var _a;
        __classPrivateFieldSet(this, _AnimeCardController_isRemoved, true, "f");
        (_a = __classPrivateFieldGet(this, _AnimeCardController_subscriptions, "f")) === null || _a === void 0 ? void 0 : _a.unsubscribe();
        if (!__classPrivateFieldGet(this, _AnimeCardController_hasBeenInitialized, "f")) {
            return;
        }
        __classPrivateFieldGet(this, _AnimeCardController_instances, "m", _AnimeCardController_teardownCountdown).call(this);
        __classPrivateFieldGet(this, _AnimeCardController_lazyLoader, "f").unobserve(this.posterTarget);
        __classPrivateFieldGet(this, _AnimeCardController_ioUtil, "f").unobserve(this.element);
    }
    get userPreferredTitle() {
        if (!__classPrivateFieldGet(this, _AnimeCardController_userPreferredTitleValue, "f")) {
            __classPrivateFieldSet(this, _AnimeCardController_userPreferredTitleValue, this.mainTitleTarget.textContent, "f");
        }
        return __classPrivateFieldGet(this, _AnimeCardController_userPreferredTitleValue, "f");
    }
    get alternateTitles() {
        if (!__classPrivateFieldGet(this, _AnimeCardController_alternateTitlesValue, "f")) {
            const { dataset } = this.element;
            __classPrivateFieldSet(this, _AnimeCardController_alternateTitlesValue, JSON.parse(dataset.alternate), "f");
        }
        return __classPrivateFieldGet(this, _AnimeCardController_alternateTitlesValue, "f");
    }
    showVideos(e) {
        e === null || e === void 0 ? void 0 : e.preventDefault();
        AnimeVideosModal.show(__classPrivateFieldGet(this, _AnimeCardController_animeId, "f"), this.userPreferredTitle);
    }
    showStreams(e) {
        e === null || e === void 0 ? void 0 : e.preventDefault();
        AnimeStreamsModal.show(__classPrivateFieldGet(this, _AnimeCardController_animeId, "f"), this.userPreferredTitle);
    }
    showLinks(e) {
        e === null || e === void 0 ? void 0 : e.preventDefault();
        AnimeLinksModal.show(__classPrivateFieldGet(this, _AnimeCardController_animeId, "f"), this.userPreferredTitle);
    }
    showLibraryEditor(e) {
        e === null || e === void 0 ? void 0 : e.preventDefault();
        LibraryEditor.show(__classPrivateFieldGet(this, _AnimeCardController_animeId, "f"), this.userPreferredTitle);
    }
    showLibraryInput(e) {
        e === null || e === void 0 ? void 0 : e.preventDefault();
        this.showLibraryEditor();
    }
    hideMarkMenu(e) {
        if (e && e.type !== 'mouseout' && this.element.contains(e.target)) {
            return;
        }
        this.marksMenuTarget.classList.remove('-open');
        if (__classPrivateFieldGet(this, _AnimeCardController_closeCallback, "f")) {
            __classPrivateFieldSet(this, _AnimeCardController_closeCallback, null, "f");
            window.removeEventListener('scroll', __classPrivateFieldGet(this, _AnimeCardController_closeCallback, "f"));
            window.removeEventListener('click', __classPrivateFieldGet(this, _AnimeCardController_closeCallback, "f"));
        }
    }
    showMarkMenu(e) {
        if (!__classPrivateFieldGet(this, _AnimeCardController_closeCallback, "f")) {
            __classPrivateFieldSet(this, _AnimeCardController_closeCallback, this.hideMarkMenu.bind(this), "f");
            window.addEventListener('scroll', __classPrivateFieldGet(this, _AnimeCardController_closeCallback, "f"));
            window.addEventListener('click', __classPrivateFieldGet(this, _AnimeCardController_closeCallback, "f"));
        }
        this.marksMenuTarget.classList.add('-open');
    }
    showPremiereDateTime(e) {
        e === null || e === void 0 ? void 0 : e.preventDefault();
        const { dataset } = this.element;
        if (+dataset.premierePrecision >= 4) {
            showTimeZoneConversionModal(dataset.premiere, `${this.userPreferredTitle} Premiere`);
        }
    }
    showEpisodeTime(e) {
        e === null || e === void 0 ? void 0 : e.preventDefault();
        if (this.hasCountdownTarget) {
            const { countdownTarget } = this;
            const { dataset } = countdownTarget;
            const label = [this.userPreferredTitle];
            const { label: episodeLabel } = dataset;
            if (episodeLabel) {
                label.push(`(${episodeLabel})`);
            }
            showTimeZoneConversionModal(dataset.timestamp, label.join(' '));
        }
    }
    revealSynopsis() {
        if (__classPrivateFieldGet(this, _AnimeCardController_synopsisRevealed, "f")) {
            return;
        }
        __classPrivateFieldSet(this, _AnimeCardController_synopsisRevealed, true, "f");
        if (this.hasSynopsisSpoilerWarningTarget) {
            this.synopsisSpoilerWarningTarget.remove();
        }
        if (this.hasSynopsisTarget) {
            this.synopsisTarget.classList.remove('is-spoiler-masked');
        }
    }
    setLibraryStatus(e) {
        const savedStatus = Viewer.library.toggleStatusOf(__classPrivateFieldGet(this, _AnimeCardController_animeId, "f"), e.target.dataset.libraryStatus);
        __classPrivateFieldGet(this, _AnimeCardController_instances, "m", _AnimeCardController_applyLibraryStatus).call(this, savedStatus);
        this.hideMarkMenu();
    }
}
_AnimeCardController_animeId = new WeakMap(), _AnimeCardController_userPreferredTitleValue = new WeakMap(), _AnimeCardController_romajiTitle = new WeakMap(), _AnimeCardController_englishTitle = new WeakMap(), _AnimeCardController_nativeTitle = new WeakMap(), _AnimeCardController_alternateTitlesValue = new WeakMap(), _AnimeCardController_cachedSearchQueryString = new WeakMap(), _AnimeCardController_hasBeenInitialized = new WeakMap(), _AnimeCardController_isRemoved = new WeakMap(), _AnimeCardController_isVisible = new WeakMap(), _AnimeCardController_synopsisRevealed = new WeakMap(), _AnimeCardController_countdownEntry = new WeakMap(), _AnimeCardController_libraryInitialized = new WeakMap(), _AnimeCardController_subscriptions = new WeakMap(), _AnimeCardController_closeCallback = new WeakMap(), _AnimeCardController_ioUtil = new WeakMap(), _AnimeCardController_lazyLoader = new WeakMap(), _AnimeCardController_instances = new WeakSet(), _AnimeCardController_initializeLibrary = function _AnimeCardController_initializeLibrary(animeId, initialLibraryStatus) {
    if (__classPrivateFieldGet(this, _AnimeCardController_libraryInitialized, "f")) {
        return;
    }
    __classPrivateFieldSet(this, _AnimeCardController_libraryInitialized, true, "f");
    if (this.element.querySelector('[data-controller="mark-icon"]')) {
        return;
    }
    import(/* webpackChunkName: "anime_card_marks" */ 'templates/anime_card_marks.handlebars')
        .then(({ default: template }) => handlebarsCompilerFactor.getCompiled('anime_card_marks', template, { status: initialLibraryStatus })).then((libraryElements) => {
        this.element.querySelector('.anime-card').prepend(libraryElements);
    });
}, _AnimeCardController_teardownCountdown = function _AnimeCardController_teardownCountdown() {
    const countdownEntry = __classPrivateFieldGet(this, _AnimeCardController_countdownEntry, "f");
    if (countdownEntry) {
        COUNTDOWN_RUNNER.remove(countdownEntry);
        __classPrivateFieldSet(this, _AnimeCardController_countdownEntry, null, "f");
    }
}, _AnimeCardController_createEventData_get = function _AnimeCardController_createEventData_get() {
    return {
        animeId: __classPrivateFieldGet(this, _AnimeCardController_animeId, "f"),
        element: this.element,
    };
}, _AnimeCardController_applyLibraryStatus = function _AnimeCardController_applyLibraryStatus(status) {
    const { element } = this;
    if (this.hasMarkIconTarget) {
        this.markIconTarget.setAttribute(MARK_ICON_VIEWER_STATUS_ATTRIBUTE_KEY, status);
    }
    setLibraryStatusAttribute(element, status);
    if (MARK_FILTERING_ENABLED) {
        const { classList } = element;
        if (MarkFilter.isLibraryStatusFiltered(status)) {
            if (__classPrivateFieldGet(this, _AnimeCardController_hasBeenInitialized, "f")) {
                classList.add(HIDDEN_BY_MARK_FILTER_CSS_CLASS);
            }
            else {
                __classPrivateFieldGet(this, _AnimeCardController_instances, "m", _AnimeCardController_remove).call(this);
            }
        }
        else if (classList.contains(HIDDEN_BY_MARK_FILTER_CSS_CLASS)) {
            classList.remove(HIDDEN_BY_MARK_FILTER_CSS_CLASS);
        }
    }
    if (!__classPrivateFieldGet(this, _AnimeCardController_isRemoved, "f")
        && (status === LibraryStatus.Watching || status === LibraryStatus.Completed)
        && !__classPrivateFieldGet(this, _AnimeCardController_synopsisRevealed, "f")
        && Viewer.preferences.revealSpoilers) {
        this.revealSynopsis();
    }
}, _AnimeCardController_remove = function _AnimeCardController_remove() {
    __classPrivateFieldSet(this, _AnimeCardController_isRemoved, true, "f");
    this.element.remove();
}, _AnimeCardController_hide = function _AnimeCardController_hide() {
    if (__classPrivateFieldGet(this, _AnimeCardController_isVisible, "f") !== false) {
        __classPrivateFieldSet(this, _AnimeCardController_isVisible, false, "f");
        this.element.classList.add('hide');
        this.dispatch('visibilitychanged', { detail: { isVisible: false, animeId: __classPrivateFieldGet(this, _AnimeCardController_animeId, "f") } });
    }
}, _AnimeCardController_unhide = function _AnimeCardController_unhide() {
    if (__classPrivateFieldGet(this, _AnimeCardController_isVisible, "f") !== true) {
        __classPrivateFieldSet(this, _AnimeCardController_isVisible, true, "f");
        this.element.classList.remove('hide');
        this.dispatch('visibilitychanged', { detail: { isVisible: true, animeId: __classPrivateFieldGet(this, _AnimeCardController_animeId, "f") } });
    }
}, _AnimeCardController_matchesSearchQuery = function _AnimeCardController_matchesSearchQuery(lowercaseTerm) {
    return __classPrivateFieldGet(this, _AnimeCardController_instances, "a", _AnimeCardController_searchQueryString_get).includes(lowercaseTerm);
}, _AnimeCardController_searchQueryString_get = function _AnimeCardController_searchQueryString_get() {
    if (!__classPrivateFieldGet(this, _AnimeCardController_cachedSearchQueryString, "f")) {
        const searchStrings = [
            __classPrivateFieldGet(this, _AnimeCardController_romajiTitle, "f"),
            __classPrivateFieldGet(this, _AnimeCardController_englishTitle, "f"),
            __classPrivateFieldGet(this, _AnimeCardController_nativeTitle, "f"),
            this.alternateTitles,
        ];
        this.studioLinkTargets.forEach((link) => {
            searchStrings.push(link.textContent);
        });
        __classPrivateFieldSet(this, _AnimeCardController_cachedSearchQueryString, searchStrings.join(';;').toLowerCase(), "f");
    }
    return __classPrivateFieldGet(this, _AnimeCardController_cachedSearchQueryString, "f");
};
AnimeCardController.targets = [
    'studioLink',
    'mainTitle',
    'countdown',
    'poster',
    'synopsis',
    'synopsisSpoilerWarning',
    'markIcon',
    'marksMenu',
    'posterContainer',
    'releaseOverlay',
];
application.register('page-search', PageSearchController);
application.register('anime-card-list', AnimeCardListController);
application.register('anime-card', AnimeCardController);
const { gtag } = window;
const ANALYTICS_AVAILABLE = typeof gtag !== 'undefined';
if (ANALYTICS_AVAILABLE) {
    const relationMap = {
        'website-icon': 'website',
        'preview-icon': 'preview',
        'mal-icon': 'mal',
        'anilist-icon': 'anilist',
        'anime-planet-icon': 'anime_planet',
        'anidb-icon': 'anidb',
        'anisearch-icon': 'anisearch',
        'ann-icon': 'anime_news_network',
        'kitsu-icon': 'kitsu',
        'crunchyroll-icon': 'crunchyroll',
        'twitter-icon': 'twitter',
        'watch-icon': 'streaming',
    };
    // Delegated click tracker for related links
    document.addEventListener('mouseup', (e) => {
        const target = e.target;
        if (target.matches('.related-links a')) {
            const relation = relationMap[target.getAttribute('class')];
            // Only record valid relations
            if (relation) {
                trackRelatedLinkClick(relation, target.href);
            }
        }
    });
}
document.addEventListener('click', (e) => {
    const target = e.target;
    if (target.matches('.preference-bar-mark-filter-button')) {
        e.preventDefault();
        MarkFilterModal.show(target.dataset.scope);
    }
    else if (ANALYTICS_AVAILABLE && target.matches('.stream-link')) {
        gtag('event', target.textContent, {
            event_category: 'related link',
            event_label: target.href,
        });
    }
});
