import { application } from '@local-stimulus';
import ViewerLanguageManager from '@root/javascript/util/viewer-language-manager';
function showOnboardingModal() {
    Promise.all([
        import(/* webpackChunkName: "onboarding_modal_controller" */ './controller'),
        import(/* webpackChunkName: "onboarding_modal" */ './modal.html'),
        import(/* webpackChunkName: "html_parser" */ '@root/javascript/util/html/parser'),
    ]).then(([{ default: OnboardingModalController }, { default: onboardingModalHTML }, { default: parseHtml }]) => {
        application.register('rls-onboard-modal', OnboardingModalController);
        const html = parseHtml(onboardingModalHTML);
        document.body.appendChild(html);
    });
}
function conditionallyShowOnboardingModal() {
    var _a;
    if (!window.navigator.cookieEnabled) {
        // No cookie support means we can't know when to not show the onboarding modal.
        return false;
    }
    if (window.Viewer.isLoggedIn) {
        if (((_a = document.head.querySelector('[name="skip-rls-onboarding"]')) === null || _a === void 0 ? void 0 : _a.content) === '1') {
            return false;
        }
    }
    else {
        if (window.Viewer.preferences.has('default_release_schedules')) {
            return false;
        }
    }
    if (!ViewerLanguageManager.getAll().has('en')) {
        return false;
    }
    if (typeof window.gtag !== undefined) {
        window.gtag('event', 'modal_view', {
            event_category: 'release schedule onboarding',
            event_label: window.location.href,
        });
    }
    showOnboardingModal();
    return true;
}
export { conditionallyShowOnboardingModal, showOnboardingModal };
